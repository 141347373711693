import clsx from 'clsx'

import ButtonContent from './ButtonContent'
import css from './scss/Button.module.scss'
import { ButtonProps } from './ts'

function Button(props: ButtonProps) {
  const {
    icon,
    text,
    color = 'violet',
    variant = 'rounded',
    alignment = 'center',
    isDisabled,
    isLoading,
    ...otherProps
  } = props

  return (
    <button
      {...otherProps}
      className={clsx(
        css.button,
        {
          'justify-center': alignment === 'center',
          'flex-row-reverse': alignment === 'reverse',
          [css['button--flat']]: variant === 'flat',
          [css['button--rounded']]: variant === 'rounded',
          [css['button--flat-full']]: variant === 'flat-full',
          [css['button--flat-outline']]: variant === 'flat-outline',
          [css['button--rounded-outline']]: variant === 'rounded-outline',
          [css['button--rounded-full']]: variant === 'rounded-full',
          [css['button--rounded-outline-full']]:
            variant === 'rounded-outline-full',
          [css['button--plain']]: variant === 'plain',
          [css[`button--${color}`]]: color,
        },
        otherProps.className
      )}
      disabled={isDisabled}
    >
      <ButtonContent
        icon={icon}
        text={text}
        color={color}
        alignment={alignment}
        isLoading={isLoading}
      />
    </button>
  )
}

export default Button
