
// @ts-ignore
    import __i18nConfig from '@next-translate-root/i18n'
// @ts-ignore
    import __loadNamespaces from 'next-translate/loadNamespaces'
// @ts-ignore
    
import Head from "next/head"

import ErrorTemplate from "@/templates/Error"
import ErrorView from "@/views/error"

function NotFoundErrorPage() {
  return (
    <>
      <Head>
        <title>Błąd 404 | fansi.pl</title>
      </Head>
      <ErrorView code="404" />
    </>
  )
}

NotFoundErrorPage.Layout = ErrorTemplate

export default NotFoundErrorPage


// @ts-ignore
    export async function getStaticProps(ctx) {
// @ts-ignore
        
// @ts-ignore
        
// @ts-ignore
        return {
// @ts-ignore
          
// @ts-ignore
          props: {
// @ts-ignore
            
// @ts-ignore
            ...(await __loadNamespaces({
// @ts-ignore
              ...ctx,
// @ts-ignore
              pathname: '/404',
// @ts-ignore
              loaderName: 'getStaticProps',
// @ts-ignore
              ...__i18nConfig,
// @ts-ignore
              
// @ts-ignore
            }))
// @ts-ignore
          }
// @ts-ignore
        }
// @ts-ignore
    }
// @ts-ignore
  