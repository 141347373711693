import GET_PRODUCTS_BY_IDS from '@/graphql/queries/products/getProductsByIds'
import Hasura from '@/services/Hasura/Hasura'
import Product from '@/ts/Product'

export async function getBestsellerProducts(productsIds: number[]) {
  const queryProducts = await Hasura.request<{ products: Product[] }>(
    GET_PRODUCTS_BY_IDS,
    { id: productsIds },
    { 'x-hasura-role': 'user' }
  )
  return queryProducts
}
