import { useRouter } from 'next/router'
import useTranslation from 'next-translate/useTranslation'

import Button from '@/atoms/Button/Button'
import Spinner from '@/atoms/Spinner'
import FeaturedProducts from '@/organisms/FeaturedProducts'
import { useBestsellerProducts } from '@/organisms/FeaturedProducts/logic/useBestsellerProducts'

function ErrorView({ code, customMessage }: ErrorViewProps) {
  const { push } = useRouter()
  const { t } = useTranslation('global')

  const { data } = useBestsellerProducts()

  return (
    <>
      <div className="relative flex flex-col items-center justify-start space-y-4 lg:h-full wide:justify-end">
        <div className="lg:absolute z-10 bottom-0 lg:bottom-4 flex flex-col items-center py-6 justify-center flex-1 flex-shrink-0 wide:absolute wide:mb-[4vh] wide:text-white">
          <h1 className="text-2xl wide:text-[3vh] font-bold">
            {t('errors_something_went_wrong')}
          </h1>
          <p className="text-[14px] wide:text-[1.35vh] font-medium mt-1 wide:mt-[0.5vh] text-center">
            {customMessage ?? t('errors_page_not_found')}
          </p>
          <Button
            text={t('errors_go_back')}
            className="px-4 py-3 mt-4 wide:mt-[2vh] wide:text-[1.6vh] wide:!bg-sbtviolet-700 wide:hover:!bg-sbtviolet-650"
            onClick={() => push('/')}
          />
        </div>
        <img
          src={`/${code}.svg`}
          draggable={false}
          className="wide:absolute portrait:max-h-full portrait:w-[100%] wide:max-h-full"
        />
      </div>
      <div className="h-full">
        {data ? (
          <FeaturedProducts products={data?.products} bestsellers />
        ) : (
          <Spinner />
        )}
      </div>
    </>
  )
}

interface ErrorViewProps {
  code: '404' | '500'
  customMessage?: string
}

export default ErrorView
