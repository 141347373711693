import clsx from 'clsx'

import Spinner from '../Spinner'
import css from './scss/Button.module.scss'
import { ButtonContentProps } from './ts'

function ButtonContent(props: ButtonContentProps) {
  const { isLoading, icon, text, alignment } = props

  if (isLoading) return <Spinner className="text-white" />

  return (
    <>
      {icon && <span className={css.icon}>{icon}</span>}
      {text && (
        <span
          className={clsx(css.text, {
            'mx-auto leading-none': alignment === 'left-center',
            'mr-4 mt-0.5': alignment === 'reverse',
          })}
        >
          {text}
        </span>
      )}
    </>
  )
}

export default ButtonContent
