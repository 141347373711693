import { gql } from 'graphql-request'

const GET_PRODUCTS_BY_IDS = gql`
  query getProductsByIds($id: [bigint!], $type: [String!]) {
    products(
      where: {
        _and: [
          { id: { _in: $id } }
          { type: { _in: $type } }
          { visible: { _eq: "true" } }
        ]
      }
    ) {
      id
      slug
      status
      name
      tags
      orders_count
      description
      cover: main_photo_url
      preorder
      embedded_link
      release_date
      bestseller
      newness
      visible
      images: product_photos {
        id
        src: url
      }
      created_at
      creator {
        id
        slug
        nickname
        avatar: avatar_link
      }
      type
      categories: product_categories {
        id: product_category_id
        category: product_category {
          id
          name
        }
      }
      duration: duration_of_event
      price {
        currency: price_currency
        value: price_subunit
        price_before_discount: price_before_discount_subunit
      }
      # fansi_collection: fansi_collection_group {
      #   id
      #   name
      # }
      reviews(
        where: { visible: { _eq: true } }
        order_by: { created_at: desc }
      ) {
        id
        created_at
        opinion
        rating
        user_name
      }
      voucher_enabled
      rating: reviews_aggregate {
        aggregate {
          avg {
            value: rating
          }
        }
      }
      votes: reviews_aggregate {
        aggregate {
          count
        }
      }
      sizes: product_sizes(
        order_by: { name: desc }
        where: { quantity: { _is_null: false } }
      ) {
        id
        name
        quantity
      }
      time_slots: product_time_slots(
        order_by: { date: asc }
        where: {
          _not: {
            fansi_class_status: {
              _in: ["fansi_class_done", "fansi_class_cancelled"]
            }
          }
        }
      ) {
        id
        date
        status: fansi_class_status
        participants_number: orders_aggregate {
          aggregate {
            count
          }
        }
      }
      max_participants: max_number_of_users
      terms: terms_path
      contest_instructions
      contest_start: contest_start_date
      contest_end: contest_end_date
      benefits: product_contest_benefits(order_by: { position: asc }) {
        id
        content
      }
      entries: product_contest_entries {
        id
        name
        price {
          value: price_subunit
          currency: price_currency
        }
        benefits: product_contest_entry_benefits(order_by: { position: asc }) {
          id
          link
          product_id
          content
        }
      }
      realizations: orders_products(
        where: {
          order: {
            fansi_video_url: { _is_null: false }
            fansi_video_visible_creator: { _eq: true }
            fansi_video_visible_user: { _eq: true }
          }
        }
      ) {
        order {
          youtube_id
        }
      }
    }
  }
`

export default GET_PRODUCTS_BY_IDS
