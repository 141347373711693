import Header from '@/organisms/Header'

function ErrorTemplate({ children }: Template) {
  return (
    <>
      <style jsx global>
        {`
          body {
            height: 100vh;
          }
          #__next {
            height: 170vh;
          }
        `}
      </style>
      <Header />
      <>{children}</>
    </>
  )
}

export default ErrorTemplate
