import useTranslation from 'next-translate/useTranslation'

import ProductSlider from '@/organisms/ProductSlider'
import Product from '@/ts/Product'

function FeaturedProducts({ products, bestsellers }: FeaturedProductsProps) {
  const { t } = useTranslation('products')

  if (!products || products.length <= 0) return null

  return (
    <div>
      <p className="my-12 text-2xl font-medium text-center">
        {t(`${bestsellers ? 'bestsellers' : 'similar_products'}`)}
      </p>
      <ProductSlider products={products} />
    </div>
  )
}

interface FeaturedProductsProps {
  bestsellers?: boolean
  products: Product[] | undefined
}

export default FeaturedProducts
