import { useQuery } from 'react-query'

import getEndpoint from '@/helpers/getEndpoint'
import API from '@/services/API'

import { getBestsellerProducts } from './getBestsellerProducts'

export function useBestsellerProducts() {
  const endpoint = getEndpoint('CHECKOUT_FEATURED_PRODUCTS')

  return useQuery('bestsellers', async () => {
    const response = await API.get<{ ids: number[] }>(endpoint, {})
    if (response.status === 'success') {
      return getBestsellerProducts(response.content.ids)
    }
  })
}
